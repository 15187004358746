//navigation

.menuMain {
  margin-bottom: 0rem;
}

//donate button
.cta-button.donate {
  border: 1px solid $green;
}

//carousel

.carouselSlideTitle {
  position: relative; // otherwise: https://www.bugherd.com/projects/174428/tasks/27
}

// .carouselSlideHeading a {
//   @include highlighter;
// }

.carouselButtons .activeSlide a {
  background-color: $orange;
}

//homeBox3

.homeBox3 {
  .homeFeatureDetailsWrapper {
    z-index: 2;

    h2 a {
      @include highlighter;
    }
  }
}

.homeBox3 [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
  max-width: 650px;
}

// .homeBox3:hover {
//   .homeFeatureDetailsWrapper h2 a {
//     background: $green;
//   }
//   &:after {
//     content: none;
//   }
// }


//footer
ul.footerLinks {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 5px;
  }
}

.footerBox3 {
  margin-top: 0;
  text-align: center;
  font-size: 14px;

  p {
    padding: 10px 0 0;
  }
}

.footerlogos {
  display: table;
  margin: 0 auto;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.pageFooter ul.socialIcons {
  margin: 20px auto 20px;
  display: table;

  li {
    display: inline-block;
  }
}

//underlines

.homeIntro {
  h2 {
    @include heading-underline($colour: $green,
      $width: 50px,
      $height: 6px,
      $justify: center,
      $margin-top: 1rem,
      $margin-bottom: 1rem);
  }
}

// .homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
//   @include heading-underline(
//     $colour: $orange,
//     $width: 50px,
//     $height: 6px,
//     $justify: center,
//     $margin-top: 1rem,
//     $margin-bottom: 1rem
//   );
// }

.homefeaturecategory-homeboximpactstats td:first-child h2 {
  @include heading-underline(
    $colour: $orange,
    $width: 50px,
    $height: 6px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem);
}

.homeFeed .feedsTitle {
  @include heading-underline(
    $colour: $brand-primary,
    $width: 100%,
    $height: 6px,
    $justify: left,
    $margin-top: 0.75rem,
    $margin-bottom: 1.5rem);
}

//donate button

.button.donate,
button.donate {
  border: 1px solid #009646;
}

//totaliser

.totaliserDone {
  background-color: $orange;
}

//newsletter btn
.footerBox[class*="ewsletter"] button {
  padding: 0 2em;
}

.footerBox[class*="ewsletter"] .featureForm>div:first-child .formQuestion {
  margin-right: 1rem;
}



.homeBox6 {
  max-width: 1240px;
  margin: 0 auto;
  display: table;
  padding: 40px;

  .featurelogo {
    display: flex;
  }

  h2 {
    text-align: center;
  }

  img {
    width: 70%;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    padding: 20px 30px;
  }
}

.carouselDetailWrapper {

  .button.donate,
  button.donate {
    background-color: $green;
    color: white;
  }
}

@media (max-width: 500px) {
  .carouselSlideHeading {
    font-size: 2em;
  }
}

a.cta-button-green {
  @include button;
  background-color: $green;
  color: $white;
  &:hover {
    background-color: darken($green, 10%);
    color: $white;
  }
}
a.cta-button-orange {
  @include button;
  background-color: $orange;
  color: $white;
  &:hover {
    background-color: darken($orange, 10%);
    color: $white;
  }
}

blockquote.green-black {
  background-color: $green;
  color: $grey;
}

blockquote { position: relative; }
blockquote.black-green:before,
blockquote.black-orange:before,
blockquote.green-black:before {
  content: '';
  position: absolute;
  height: 4px;
  width: 15%;
  bottom: $spacer;
  right: -$spacer;
}

blockquote.black-green:before {
  background-color: $green;
}
blockquote.black-orange:before {
  background-color: $orange;
}
blockquote.green-black:before {
  background-color: $grey;
}

// .line-through-green,
// .line-through-orange,
// .line-through-black {
//   text-decoration: none !important;
//   position: relative;
//   &:before {
//     content: '';
//     position: absolute;
//     width: 45%;
//     height: 2px;
//     top: 57%;
//     left: $spacer * -1.5;
//   }
// }
// .line-through-green:before { background-color: $green; }
// .line-through-orange:before { background-color: $orange; }
// .line-through-black:before { background-color: $grey; }


h1.title { position: realtive; }
h1.title:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url($assets-path + "arrows.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}


.homepage-quickgiving--container {
  flex-direction: column;
  .homepage-quickgiving--copy {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.quickGivingPanel .formQuestion.donationAmount.donationSelected {
  box-shadow: inset 0 0 0 3px $brand-primary;
}

// Mailchimp DES-4900
#mc_embed_signup {
  max-width: $container-max-width;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  #mc_embed_signup_scroll {
    display: flex;
    flex-wrap: wrap;
  }
  .mc-field-group {
    flex-grow: 1;
    padding: 10px;
    label {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
  #mce-responses {
    display: none;
  }
  .clear {
    padding: 10px;
    width: 200px;
    margin-top: 32px;
    input {
      height: 38px;
      background-color: $green;
    }
  }
}

// Quick exit button https://raisingit.atlassian.net/browse/CR-279
.quick-exit {
  padding: $spacer;
  position: fixed;
  top: 230px;
  right: -56px;
  transform: rotate(-90deg);
  box-shadow: 0 0 30px rgba(black,0.4);
  background-color: $brand-primary;
  font-size: 1.25em;
  z-index: 1;

  &:before {
      content:"\f057\2000";
      font-family: "FontAwesome", icon;
  }
  &:hover {
      background-color: $brand-secondary;
  }
}