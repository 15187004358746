// $platform-name: 'youthprojectsltd';

//
// Environment
//

//
// Config values
//
$platform-name: "youthprojectsltd";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: black;
$brand-secondary: blue;
$grey: #000;
$donate-colour: $brand-primary;
$green: #009646;
$orange: #FF8A00;

// Create greyscale

// Utility colours

//
// Layout
//
$container-max-width: 1230px;
// Breakpoints

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
//
// Misc
//
$body-colour: #666666;
//
// Transitions
//

//
// Logo
//
$logo: "logo.svg";
$logo-width: 245px;
$logo-height: 118px;
$logo-absolute: true;

//
// Typography
//
$font-open-sans: "Roboto", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Roboto", sans-serif;
$headings-colour: $grey;
$font-weight-bold: 600;
// $headings-text-transform: none;
// $headings-letter-spacing: 0.05em;
// Links

// Icon font

//
// Buttons
//
$border-radius: 1px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;
$btn-font-family: $font-open-sans;
$btn-font-weight: 600;
$btn-text-transform: uppercase;

// Donate button
$donate-btn-background-colour: transparent;
$donate-btn-colour: $green;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: $brand-primary;
$social-icons-colour: #fff;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $brand-primary;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: $grey;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//

// Header content
$header-content-margin-bottom: 0;
$header-content-padding-top: 0px;
$header-content-padding-bottom: 0px;
// Tagline

// Main call to action
$cta-btn-gap: 20px;

// Search
$header-search-margin-right: 15px;

// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
$header-search-button-background-colour: $brand-primary;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-type: normal;
$nav-background-colour: transparent;
$nav-normal-align-items: right;
$nav-top-level-item-colour: #000;
$nav-font-family: $font-open-sans;
// Top level items
$nav-top-level-item-padding: 15px 20px;
// Submenus

// Burger button

// Nav normal

//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-max-width: 100%;
$carousel-contents-max-width: 1440px;
// $carousel-image-overlay: rgba(#000, 0.5);
$carousel-image-overlay: transparent;
$carousel-details-background-colour: $grey;
$carousel-details-position-x: left;
$carousel-details-position-y: bottom;
$carousel-details-text-align: left;
$carousel-heading-colour: white;
$carousel-heading-font-size: 2.5em;
$carousel-details-max-width: 600px;
// $carousel-summary-colour: #fff;
// $carousel-summary-font-size: 1.25em;
$carousel-details-margin-y: $spacer * 5;
$carousel-read-more-enabled: false;
$carousel-details-padding: $spacer * 2;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-font-size: 2em;
$carousel-summary-enabled: true;
$carousel-summary-colour: #FFF;

// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)
$carousel-controls-icon-colour: white;
// Carousel buttons dots
$carousel-dots-colour: $orange;
$carousel-dots-active-colour: $orange;
// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-max-width: 1190px;
$home-intro-background-colour: white;
$home-intro-font-size: 1.25em;
$home-intro-padding-y: $spacer * 1;
$home-intro-text-align: center;
$home-intro-colour: $grey;
//
// Home features
//

//
// Impact stats
$impact-stats-background-image: "GetImage-1.png";
$impact-stats-background-image-opacity: 0.15;

// Heading

// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: #ece9e9;
// Feeds title
$feeds-title-colour: $brand-primary;
// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 3;
$footer-prefab: 4;
// Footer admin links

// Newsletter
$newsletter-button-background-colour: $green;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
$post-content-max-width: 900px;

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//
$sidebar-enabled: false;
// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
$blockquote-background-colour: $grey;
$blockquote-border-radius: 0;
$blockquote-margin-y: $spacer * 2;
$blockquote-padding: $spacer * 2;
$blockquote-text-align: left;
$blockquote-font-size: 1.5em;
$blockquote-font-family: $headings-font-family;
$blockquote-line-height: 1.25;
$blockquote-colour: text-contrast($blockquote-background-colour);

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//
$quick-giving-background-colour: $green;
$quick-giving-donation-amount-figure-colour: $brand-primary;
//
// Home quick giving panel
//
$home-quick-giving-background-colour: $green;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: rgba($brand-primary, 0.65);
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $brand-primary;
$home-quick-giving-donate-btn-background-colour: $brand-primary;
$home-quick-giving-donate-btn-border-colour: $brand-primary;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-colour: $white;
$home-quick-giving-heading-colour: $white;
// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite logo

// Subsite nav

//
// Cookie consent
//
